:root {
  --primary-color: #69A120;
  --secondary-color: #343434;
  --background-color: #3c3c3c;

  --border-radius: 2px;

  --green: #69A120;
}

::-webkit-input-placeholder { /* Edge */
  color: #ccc;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

#root {
  margin-bottom: -20px;
  content-visibility: auto;
}


@page {
  margin: 1cm;
}


@media print {

  html {
    font-size: 12px !important;
    zoom: 0.9;
  }
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
  }

  .ch2 {
    display: none; /* Hide cookieshub */
  }

  header.header {
    box-shadow: none;
    background: transparent !important;
    margin-bottom: -20px;
  }
  header.header ul {
    display: none;
  }
  .report-info {
    margin-top: -80px;
    margin-bottom: 50px;
  }
  .section {
    filter: none !important;
  }
  .section > div {
    background: transparent !important;
    margin-top: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    clip-path: none !important;
  }
  .fuk-button {
    display: none !important;
  }


  .audit-footer {
    border-top: 1px solid #ccc;
    margin-top: 0 !important;
  }
  .audit-footer .fuk-grid {
    display: block;
  }
  .audit-footer img {
    display: none;
  }
  .audit-footer > div {
    clip-path: none !important;
  }
  a.activate-button {
    display: none !important;
  }

  .empty {
    margin: 5px 20px !important;
  }
  .footer {
    border-top: 1px solid #ccc;
    background: transparent !important;
    color: #333 !important;
    padding-top: 20px !important;
    margin-top: 0 !important;
  }

  .footer img {
    display: none;
  }

  .competitors .fuk-grid {
    display: block;
  }

  .competitors-graph {
    width: 90% !important;
  }
  .npbreak {
    page-break-inside: avoid;
  }
  .pbreak {
    page-break-before: always;
  }
  .other-audits {
    display: none;
  }
}


body a, body a:visited, body a:hover, body a:active {
  color: #729A3C;
}